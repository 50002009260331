import moment from 'moment';
import { TimeDuration } from '@/helper/TimeDuration';
import User from '@/models/User';
import Company from '@/models/Company';
import Customer from '@/models/Customer';
import Area from '@/models/Area';
import Location from '@/models/Location';
import {VersionControl} from '@/misc/VersionControl';

export default class CleanTime extends VersionControl {
    public static parseFromObject(object: Partial<CleanTime>): CleanTime {
        const cleanTime = new CleanTime();
        // use id only
        if (object.location ) {
            object.location = (object.location as any).id;
        } else {
            object.location = undefined; // an undefined assignment is necessary, because object.location can be null
        }
        if (object.byWeekDay) {
            object.byWeekDay = Array.from(object.byWeekDay);
        }
        Object.assign(cleanTime, object);
        return cleanTime;
    }

    public id?: string;

    /**
     * Date when the cleanTime (and it's recurrence) starts
     */
    public date!: string;
    /**
     * Array of start times for every occurrence. Every object has hour, minute and duration. Duration is in ms.
     */
    public times: TimeDuration[] = [];

    public active!: boolean;
    /**
     * While using recurs this could be the end date of the recur
     */
    public dateEnd?: string | null;
    /**
     * The frequence of the recurrence
     */
    public freq: 'daily' | 'weekly' | 'monthly' = 'daily';
    /**
     * 1 means on every occurrence. 2 on every second and so on. -1 means for example the first last of a month
     */
    public interval: number = 1;
    /**
     * Defines the weekday plus its recurrence like: 2monday, -1friday
     */
    public byWeekDay: string[] | null = [];

    public location: Location | undefined;
    public locationId!: string;
    public user?: User;
    public userId?: string | null;

    public areas: Area[] = [];

    public plannedUsers: Partial<User>[] = [];
    public company!: Company;
    public customer: Customer | undefined;
    public customerId?: string;
    public deleted!: boolean;

    public createdAt!: Date;
    public updatedAt!: Date;
    public vcIsLogEntity!: boolean;
    public vcOriginId?: string | null;
    public vcAuthorId!: string;
    public companyId!: string;
    public timeFirst!: number;
    public timesCrossDay!: boolean;

    /**
     *  Defines the recurrence (that is been used in the byWeekDay)
     */
    public recurrence?: string = '';

    // TODO: remove old attributes
    // =============================

    public origin!: string;
    // ========================


    /**
     * Sets a new time entry based on two HH:mm time strings
     * @param startTime
     * @param endTime
     * @param index
     */
    public setTime(startTime: string, endTime: string, index = 0) {
        // sets start and endTime
        const startMoment = moment(startTime, 'HH:mm');
        const endMoment = moment(endTime, 'HH:mm');

        // gets the duration by the start and endTime
        let duration = endMoment.diff(startMoment);

        // if the CleanTime is a nightshift, the duration is negtive by default
        // so we need to get the right duration by taking a day (86400000 in ms) and reducing it with the
        // absolute of the calculated, negative duration
        if (duration < 0) {
            duration = 86400000 - Math.abs(duration);
        }

        // translates the startTime and sets it for the index
        const [hour, minute] = [startMoment.hour(), startMoment.minute()];
        this.times[index] = {hour, minute, duration, occurrences: null};
    }

    /**
     * Returns the start time from index as HH:mm
     * @param index
     */
    public getStartTime(index = 0): string | null {
        if (this.times.length > 0) {
            const {hour, minute} = this.times[index]; // duration is not needed
            const tmpMoment = moment({hour, minute});
            return tmpMoment.format('HH:mm');
        } else {
            return null;
        }
    }

    /**
     * Returns the end time from index as HH:mm
     * @param index
     */
    public getEndTime(index = 0): string | null {
        if (this.times.length > 0) {
            const {duration, hour, minute} = this.times[index];
            const tmpMoment = moment({hour, minute});
            tmpMoment.add(duration, 'ms');
            return tmpMoment.format('HH:mm');
        } else {
            return null;
        }
    }

    public parseToObject(): Partial<CleanTime> {
        return {...this};
    }



    /**
     * Gets the weekday by the passed date and returns it
     * @param date
     */
    public getWeekdayByDate(date: Date): string {
        // gets the date of the job
        const currentDate: Date = new Date(date);

        // gets the weekday by the date
        const day = currentDate.getDay().toString();
        const availableDays: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const translatedDate = availableDays[day];

        // returns the date by using regex
        return translatedDate!.match(/[a-z]+/)![0];
    }

    /**
     * checks if a...
     * @param days
     */
    public isDaysInByWeekDay(days: string[]): boolean {
        if (this.byWeekDay === null) {
            return false;
        }

        // go through passed days array
        for (const day of days) {
            // try to find the same day in our byWeekDay Array
            const dayFound = this.byWeekDay.find((byWeekDay) => {
                // Match day with regex, to remove numbers
                const regDay = byWeekDay.match(/[a-z]+/)![0];
                // Check if same day was found
                return regDay === day;
            });

            // if found return true
            if (dayFound) {
                return true;
            }
        }

        // If not found return false
        return false;
    }

    // TODO: Complete method isOutDated
    /**
     * returns if the cleanTime is expired by the given dateEnd
     */
    public get isOutDated(): boolean {
        return false;
        // return this.times.every((time) => time.recurs && !time.recurs.next && !time.recurs.current);
    }

    /**
     * Tries to find the given day in our byWeekDay array
     * returns true if it was found, returns false if not.
     * @param day
     */
    public isDayInByWeek(day: string): boolean {
        if (this.byWeekDay === null) {
            return false;
        }

        this.byWeekDay!.forEach((entry) => {
            const dayMatch = entry.match(/[a-z]+/)![0];

            // day was found
            if (dayMatch === day) {
                return true;
            }
        });

        // no similar day in the array of days was found
        return false;
    }

    /**
     * formats a TimeDuration to the ISO-format
     * @param time
     */
    public formatTimeToISO(time: TimeDuration): string {
        // Build strings if either hour or minute is smaller than 10
        const hours = time!.hour < 10 ? `0${time!.hour}` : time!.hour;
        const minutes = time!.minute < 10 ? `0${time!.minute}` : time!.minute;

        // format the time to the ISO-format
        const translatedTime: string = `1970-01-01T${hours}:${minutes}:00:000Z`;

        // return the formatted time
        return translatedTime;
    }

    /**
     * returns the earliest time of the timeArray
     */
    get earliestTime(): TimeDuration | undefined {
        let returnTime: TimeDuration | undefined;

        // go through times array to get the earliest time possible
        for (const time of this.times) {
            // check if return time is null, if so set the current time
            if (!returnTime) {
                returnTime = time;
                continue;
            }

            // check if hours are smaller than the current return Time
            if (time.hour < returnTime.hour) {
                returnTime = time;
                // if hours are the same
            } else if (time.hour === returnTime.hour) {
                // check which has less minutes
                if (time.minute < returnTime.minute) {
                    returnTime = time;
                }
            }
        }

        return returnTime;
    }

    /**
     * returns the latest time of the timeArray
     */
    get latestTime(): TimeDuration | undefined {
        let returnTime: TimeDuration | undefined;

        // go through times array to get the earliest time possible
        for (const time of this.times) {
            // check if return time is null, if so set the current time
            if (!returnTime) {
                returnTime = time;
                continue;
            }

            // check if hours are bigger than the current returnTime
            if (time.hour > returnTime.hour) {
                returnTime = time;
                // if hours are the same
            } else if (time.hour === returnTime.hour) {
                // check which has more minutes
                if (time.minute > returnTime.minute) {
                    returnTime = time;
                }
            }
        }

        return returnTime;
    }

    /*
    TODO: Reintroduce if User manage has been ported to CleanTime 2
    get to(): string {
        const time = this.latestTime;

        // Check if time is undefined, return with a time of 00:00
        if (!time) {
            return `1970-01-01T00:00:00:000Z`;
        }

        // return formatted time
        return this.formatTimeToISO(time);
    }

    get from(): string {
        // Get earliest time of this clean time
        const time = this.earliestTime;

        // Check if time is undefined, return with a time of 00:00
        if (!time) {
            return `1970-01-01T00:00:00:000Z`;
        }

        // return formatted time
        return this.formatTimeToISO(time);
    }*/
}
