import Company from '@/models/Company';
import UserRole from '@/models/user-attributes/UserRole';
import CleanTime from '@/models/CleanTime';
import Address from '@/models/Address';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import Denomination from '@/models/user-attributes/Denomination';
import PaymentType from '@/models/user-attributes/PaymentType';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import {VersionControl} from '@/misc/VersionControl';
import { WEEKDAYS } from '@/Constants';

/**
 * Presents an user. A user is defined through its role.
 */
export default class User extends VersionControl {

    public static parseFromObject(object: Partial<User>): User {
        const user = new User();

        // objects can not be assigned with Object.assign to 100%. It is just an object and not an instance of the class
        // so assign first, then overwrite the objects
        Object.assign(user, object);

        user.plannedCleanTimes = CleanTime.parseFromArray(object.availableAtCleanTimes!) as CleanTime[];
        user.availableAtCleanTimes = CleanTime.parseFromArray(object.availableAtCleanTimes!) as CleanTime[];
        user.availableAtCleanTimes?.sort((a: CleanTime, b: CleanTime) => {
            const aIndex = WEEKDAYS.findIndex((weekday) => a.byWeekDay && a.byWeekDay[0] === weekday);
            const bIndex = WEEKDAYS.findIndex((weekday) => b.byWeekDay && b.byWeekDay[0] === weekday);
            return aIndex > bIndex ? 1 : -1;
        });

        if (object.role) { // sometimes this value is not populated
            user.role = UserRole.parseFromObject(object.role);
        }

        if (!object.address) {
            // Create Address for user who have no address
            object.address = new Address();
        }
        user.address = Address.parseFromObject(object.address);

        delete user.colorCode;

        return user;
    }

    // general attributes
    public id?: string;
    public email?: string;
    public firstName!: string;
    public lastName!: string;
    public phone?: string;
    public address?: Address;
    public updatedAt!: Date;
    public vcIsLogEntity!: boolean;
    public vcOriginId?: string | null;
    public vcAuthorId!: string;
    public addressId?: string;
    public companyId!: string;
    public active?: boolean;
    public maxWorkHours?: number | null;
    // normally a number. String only if the textField was cleared. If saving an empty string, the personnel number is set to null
    public personnelNumber?: string | null;
    public inviteAccepted: boolean = true;
    public oneTimePassword?: string;
    /**
     * True at default so you need to enable tracking
     */
    public skipLocationCheckOnWorkSessions: boolean = true;
    public createdAt!: string;
    private colorCode?: string;

    public role?: UserRole;
    public roleId?: string;
    public company?: Company;
    public cleanTimes: CleanTime[] = [];
    public euResident?: boolean;
    public residencePermitExpirationDate?: string;
    public workPermitExpirationDate?: string;
    public gender?: Gender;
    public employmentType?: EmploymentType;
    public healthInsurance?: string;
    public pensionInsurance?: boolean;
    public paymentType?: PaymentType;
    public vacationDays?: number;
    public birthday?: string;
    public firstDayInCompany?: string;
    public lastDayInCompany?: string;
    public denomination?: Denomination;
    public paymentInterval?: number;
    public billingDelivery?: BillingDelivery;
    public jobDescription?: string;
    public timeLimit?: string;
    public iban?: string;
    public bankName?: string;
    public socialSecurityNumber?: string;
    public taxId?: string;
    public taxClass?: string;
    public childAllowance?: number;
    public availableAtCleanTimes?: CleanTime[];
    public plannedCleanTimes?: CleanTime[] | string[];
    public managedLocations?: Location[];

    public userHasNoEmail?: boolean;

    constructor() {
        super();
        this.role = new UserRole();
        this.address = new Address();
        // at creating user set it to false. After saving, it will be undefined
        this.userHasNoEmail = false;
    }

    // RN-385 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<User> {
        const tmp: any = {...this};
        tmp.cleanTimes = [...tmp.cleanTimes];
        tmp.role = {...tmp.role};
        return tmp;
    }

    public get initials(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName[0]}${this.lastName[0]}`.toUpperCase();
        } else {
            return '';
        }
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    /**
     * Checks if the user is available for the given clean time
     * @param cleanTime
     */
    /*
    TODO: Reintroduce with new User Manage
    public isAvailableForCleanTime(cleanTime: CleanTime): boolean {
        if (!this.active) {
            return false;
        } else if (this.cleanTimes.length <= 0) {
            return true;
        } else {
            const result = this.cleanTimes
                .filter((userTime) => userTime.day === cleanTime.day)
                .filter((userTime) =>
                    moment(cleanTime.from).isBetween(userTime.from, userTime.to, undefined, '[]') ||
                    moment(cleanTime.to).isBetween(userTime.from, userTime.to, undefined, '[]'));
            return result.length > 0;
        }
    }*/
}
